/* eslint-disable linebreak-style */
import React from "react";

export function DebouncedInput({
	value: initialValue,
	onChange,
	debounce = 500,
	...props
}: {
	value: string | number
	onChange: (value: string | number) => void
	debounce?: number
  } & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
	const [value, setValue] = React.useState(initialValue);
  
	React.useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);
  
	React.useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(value);
		}, debounce);
  
		return () => clearTimeout(timeout);
	}, [value]);
  
	return (
		<input {...props} value={value} onChange={e => setValue(e.target.value)} />
	);
}

export default DebouncedInput;
