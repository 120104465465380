import React, { useState } from "react";
import { CommentsTable } from "./CommentsTable";

import "../comments.css";
import { Button } from "react-bootstrap";
import ModalWindow from "../../modal/ModalWindow";
import { CommentForm } from "../../forms/CommentForm";

export const CommentsPage = () => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);


	return(
		<div className="commentsContainer">
			<ModalWindow title="Engineering Change Information" size="xl" show={show} onClose={handleClose}>
				<CommentForm data={""} />
			</ModalWindow>
			<h1>
				Welcome <strong>Eurostar Test User!</strong>
			</h1>
			<CommentsTable />
			<div className="buttonContainer">
				<Button variant="primary" onClick={() => setShow(true)}>Add Table Entry</Button>
			</div>
		</div>
	);
};