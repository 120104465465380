/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { TableMaker } from "../../table/TableMaker";
import { CommentsData } from "../../table/dataTypes";

import {
	Column,
	Table as ReactTable,
	PaginationState,
	useReactTable,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	ColumnDef,
	OnChangeFn,
	flexRender,
} from "@tanstack/react-table";
import ModalWindow from "../../modal/ModalWindow";
import { CommentForm } from "../../forms/CommentForm";
import { useScanAllCommentsQuery } from "../../../services/commentsData";
import { useNavigate } from "react-router-dom";


export const CommentsTable = () => {
	const navigate = useNavigate();

	const columns = React.useMemo<ColumnDef<CommentsData>[]>(
		() => [
			{
				header: "EC Comments",
				columns: [
					{
						accessorKey: "ecNumber",
						header: "EC Number",
						footer: props => props.column.id,
					},
					{
						accessorKey: "revision",
						header: "Revision",
						footer: props => props.column.id,
					},
					{
						accessorKey: "status",
						header: "Status",
						footer: props => props.column.id,
					},
					{
						accessorKey: "customer",
						header: "Customer",
						footer: props => props.column.id,
					},
					{
						accessorKey: "applicableFleets",
						accessorFn: (test) => {
							if(Array.isArray(test.applicableFleets))
							{
								return test.applicableFleets.toString();
							}
							return test.applicableFleets;
						},
						header: "Fleets",
						footer: props => props.column.id,
					},
					{
						accessorKey: "openCount",
						header: "Open Count",
						enableColumnFilter: false,
						footer: props => props.column.id,
					},
					{
						accessorKey: "closedCount",
						header: "Closed Count",
						enableColumnFilter: false,
						footer: props => props.column.id,
					},
					{
						accessorKey: "awaitingApprovalCount",
						header: "Awaiting Approval",
						enableColumnFilter: false,
						footer: props => props.column.id,
					},
				],
			},
		],
		[]
	);

	const pollingInterval = 0;
	const {
		data: commentData,
		error,
		isLoading,
	} = useScanAllCommentsQuery("", {
		pollingInterval,
	});

	const [show, setShow] = useState(false);
	const [ecNumber, setECnumber] = useState("");
	const handleClose = () => setShow(false);

	// const pollingInterval = 300;
	return (
		<>
			{ isLoading ? (
				<>Loading...</>
			) : commentData ? (
				<div className="commentsTableContainer">
					<ModalWindow title="Engineering Change Information" size="xl" show={show} onClose={handleClose}>
						<CommentForm data={ecNumber} />
					</ModalWindow>
					<TableMaker data={commentData} columns={columns} handleRowClick={(rowData) => {
						console.info("Received data in parent component ", rowData);
						navigate(`/ecComment?value=${encodeURIComponent(rowData.ecNumber)}`);
					} } />
				</div>
			): null}
			
		</>);
};