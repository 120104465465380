/* eslint-disable linebreak-style */
import React, { useState } from "react";

import "../footer-styles.css";
import { Changelog } from "../../changelog/ChangeLog";
import ModalWindow from "../../modal/ModalWindow";
import { getCurrentYear } from "../../utils/dates";

export default function PageFooter() {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	const handleClick = () => {
		setShow(true);
	};
	return (
		<>
			<ModalWindow title="Changelog History" size="lg" show={show} onClose={handleClose}>
				<Changelog />
			</ModalWindow>
			<div className="footer">
				<div className="footer-container">
					<div className="footer-copyright-container">
						<p>&copy; {getCurrentYear()} Siemens Mobility Ltd </p>
					</div>
					<div className="footer-links-container">
						{/* <a
							href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI3OTc0NjE3MDg4In0/all"
							target="_blank"
							rel="noreferrer"
						>
              AMA Yammer
						</a>
						<a href="https://www.traincare.siemens.com/traincare/login" target="_blank" rel="noreferrer">
              Traincare Portal V2
						</a>
						<a href="https://irc.siemens.de/NORIS/index.do.php" target="_blank" rel="noreferrer">
              NORIS
						</a> */}
						<p onClick={() => handleClick()}>Changelog</p>
					</div>
				</div>
			</div>
		</>
	);
}
