import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { commentsApi } from "../services/commentsData";
import authReducer from "../features/auth/authSlice";


export const store = configureStore({
	reducer: {
		auth: authReducer,
		[commentsApi.reducerPath]: commentsApi.reducer,  },
	middleware: (gDM) => gDM().concat(commentsApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
