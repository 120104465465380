/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, ReactNode, useEffect, useState } from "react";

import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import jwtDecode from "jwt-decode";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectAccessToken, selectIdToken } from "../../auth";
import { loginNew, saveAccessToken } from "../../auth/authSlice";

import { loginRequest } from "./authConfig";

type Props = {
  children: ReactNode;
  loginPath?: string | undefined;
};

function timeout(delay: number) {
	return new Promise((res) => setTimeout(res, delay));
}

export type AuthToken = {
  exp?: number;
};

function CheckAuthentication({ children, loginPath }: Props) {
	const dispatch = useAppDispatch();
	const isAuthenticated = useIsAuthenticated();
	const { instance, accounts, inProgress } = useMsal();
	const tokenInfo: any = useAppSelector(selectIdToken);
	// console.info('state store token: ', tokenInfo);

	useEffect(() => {
		const intervalId = setInterval(() => {
			if (accounts.length > 0 && accounts[0].idTokenClaims !== undefined && accounts[0].idTokenClaims.exp !== undefined) {
				//assign interval to a variable to clear it.
				const requestParams = {
					scopes: ["3e7bb478-262b-4ee5-8aa7-15a36cd3f8da/.default"],
					account: accounts[0],
				};
				instance
					.acquireTokenSilent(requestParams)
					.then((response) => {
						const accessToken: AuthToken = jwtDecode(response.accessToken);
						// if (accessToken.exp !== undefined) console.info('Expiring at: ', new Date(accessToken.exp * 1000));
						dispatch(loginNew(response.idToken));
						dispatch(saveAccessToken(response.accessToken));
					})
					.catch((e) => {
						console.info("Something went wrong", e);
					});
			}
		}, 5000);

		return () => clearInterval(intervalId); //This is important
	}, [accounts, dispatch, instance]);

	useEffect(() => {
		timeout(1000);
		if (accounts !== undefined) {
			if (accounts.length == 0) {
				console.info("interaction status: ", inProgress);
				if (!isAuthenticated && inProgress == InteractionStatus.None) {
					instance
						.loginRedirect(loginRequest)
						.then((response) => {
							console.info("login response: ", response);
						})
						.catch((e) => {
							console.info("login error", e);
						});
				}
			}
		}
	}, [accounts, inProgress, instance, isAuthenticated]);

	useEffect(() => {
		if (accounts.length > 0) {
			if (accounts[0].idToken !== undefined) {
				dispatch(loginNew(accounts[0].idToken));
			}
		}
	}, [accounts, dispatch, instance]);

	return (
		<>
			{/* {console.info('isAuthenticated? ', isAuthenticated)} */}
			{isAuthenticated && tokenInfo ? <Fragment>{children}</Fragment> : <>Authenticate</>}
		</>
	);
}

export { CheckAuthentication };
