import { createSelector } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

import { RootState } from "../../app/store";

export const selectAccessTokenExpiry = (state: RootState) => state.auth.accessTokenExpiry;

export const selectRefreshTokenExpiry = (state: RootState) => state.auth.refreshTokenExpiry;

export const selectToken = (state: RootState) => state.auth.token;

export const selectAccessToken = (state: RootState) => state.auth.token?.access_token;

export const selectUser = (state: RootState) => state.auth.user;

export const selectIdToken = (state: RootState) => state.auth.idToken;

export const selectIsAuthenticated = createSelector([selectToken], (token) => {
	if (token !== undefined) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const tokenInfo = jwtDecode<any>(token.access_token);
		if (tokenInfo && tokenInfo.exp) {
			const currentTime = new Date().getTime() / 1000;
			return currentTime < tokenInfo.exp;
		}
	}
	return false;
});
