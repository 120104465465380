/* eslint-disable linebreak-style */
/* eslint-disable no-mixed-spaces-and-tabs */
import "./navBar.css";
import React from "react";

import cloudLogo from "../../../resources/AMACloud 2.png";
import siemensLogo from "../../../resources/siemensLogo.png";
import ecLogo from "../../../resources/ECComments.png";



function NavBar() {
  	return (
		<div className="container">
			<div className="siemensLogo">
				<img src={siemensLogo} alt="siemensLogo"></img>
			</div>
      
			<div className="amaLogo">
				<img src={ecLogo} alt="tiLogo"></img>
				<img src={cloudLogo} alt="cloudLogo"></img>
			</div>
		</div>
	);
}

export { NavBar };
