import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { createRoot } from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./features/newAuth/components/authConfig";
import { MsalProvider } from "@azure/msal-react";
import "bootstrap/dist/css/bootstrap.min.css";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<MsalProvider instance={msalInstance}>
				<App />
			</MsalProvider>
		</Provider>
	</React.StrictMode>
);

reportWebVitals();
