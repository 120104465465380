/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, useReactTable, flexRender, getFacetedUniqueValues, getFacetedMinMaxValues, getFacetedRowModel, RowData, Row, SortingState, getSortedRowModel, ColumnFiltersState } from "@tanstack/react-table";
import { CommentsData } from "./dataTypes";
import { Filter } from "./Filter";
import { TableProps } from "./TableMaker";
import { Table as BTable} from "react-bootstrap";

export function Table({
	data,
	columns,
	handleRowClick
}: TableProps
){

	const rerender = React.useReducer(() => ({}), {})[1];

	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
		[]
	);
	const [sorting, setSorting] = React.useState<SortingState>([]);
  
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			columnFilters,
		},
		onColumnFiltersChange: setColumnFilters,
		onSortingChange: setSorting,
		// Pipeline
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		getSortedRowModel: getSortedRowModel(),
	
		enableColumnResizing: true,
		columnResizeMode: "onChange",
		//
		debugTable: true,
	});

	return (
		<div className="p-2">
			<BTable hover responsive>
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id} className="tableHeaderRow">
							{headerGroup.headers.map(header => {
								return (
									<th key={header.id} colSpan={header.colSpan}>
										{header.isPlaceholder ? null : (
											<div className="tableHeader">
												<div
													{...{
														className: header.column.getCanSort()
															? "cursor-pointer select-none"
															: "",
														onClick: header.column.getToggleSortingHandler(),
													}}
												>
													{flexRender(
														header.column.columnDef.header,
														header.getContext()
													)}
													{{
														asc: " 🔼",
														desc: " 🔽",
													}[header.column.getIsSorted() as string] ?? null}
												</div>
											
												{header.column.getCanFilter() ? (
													<div>
														<Filter column={header.column} table={table} />
													</div>
												) : null}
											</div>
										)}
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => {
						return (
							<tr className="tableBodyRow" key={row.id} onClick={() => handleRowClick(row.original)}>
								{row.getVisibleCells().map(cell => {
									return (
										<td key={cell.id} className="tableBodyData">
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</BTable>
			<div className="h-2" />
			<div className="tablePagination">
				<button
					className="border rounded p-1"
					onClick={() => table.setPageIndex(0)}
					disabled={!table.getCanPreviousPage()}
				>
					{"<<"}
				</button>
				<button
					className="border rounded p-1"
					onClick={() => table.previousPage()}
					disabled={!table.getCanPreviousPage()}
				>
					{"<"}
				</button>
				<button
					className="border rounded p-1"
					onClick={() => table.nextPage()}
					disabled={!table.getCanNextPage()}
				>
					{">"}
				</button>
				<button
					className="border rounded p-1"
					onClick={() => table.setPageIndex(table.getPageCount() - 1)}
					disabled={!table.getCanNextPage()}
				>
					{">>"}
				</button>
				<span className="tablePaginationPageNo">
					<div>Page</div>
					<strong>
						{table.getState().pagination.pageIndex + 1} of{" "}
						{table.getPageCount()}
					</strong>
				</span>
				<span className="tablePaginationPageNo">
              | Go to page:
					<input
						type="number"
						defaultValue={table.getState().pagination.pageIndex + 1}
						onChange={e => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0;
							table.setPageIndex(page);
						}}
						className="tablePaginationInput rounded w-8"
					/>
				</span>
				<select
					className="tablePaginationSelect rounded w-16"
					value={table.getState().pagination.pageSize}
					onChange={e => {
						table.setPageSize(Number(e.target.value));
					}}
				>
					{[10, 20, 30, 40, 50].map(pageSize => (
						<option key={pageSize} value={pageSize}>
                            Show {pageSize}
						</option>
					))}
				</select>
			</div>
		</div>
	);
}