/* eslint-disable linebreak-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { FormBuilder } from "../formBuilder/FormBuilder";
import { AMAFormData, AMARowLabels } from "../formBuilder/formBuilder.interface";
import { useGetCommentsQuery, useUpdateCommentMutation } from "../../services/commentsData";
import { CommentsData } from "../table/dataTypes";

export const CommentForm = ({data} : {data: string}) => {

	console.info("Data to grab is: ", data);
	const pollingInterval = 0;
	const {
		data: commentData,
		error,
		isLoading,
	} = useGetCommentsQuery(data, {
		pollingInterval,
	});

	const [commentCount, setCommentCount] = useState(1);

	const rowLabels: AMARowLabels[] = [];

	const [updateComment, result] = useUpdateCommentMutation();

	interface options {
		value: string | number;
		label: string | number;
	}

	const arrayToOptions = (object: string[] | number[]) => {
		const newArr: [options] = [{ value: "Not Allocated", label: "Not Allocated" }];
		for (let i = 0; i < object.length; i++) newArr.push({ value: object[i], label: object[i] });
		return newArr;
	};

	const formData: AMAFormData[] = [
		{
			tab: "EC Details",
			attributeName: "ecNumber",
			label: "EC Number",
			locked: false,
			required: true,
			row: 1,
		},
		{
			tab: "EC Details",
			attributeName: "revision",
			label: "Current Revision",
			required: true,
			locked: false,
			row: 1,
		},
		{
			tab: "EC Details",
			attributeName: "status",
			label: "Status",
			locked: false,
			required: true,
			row: 1,
		},
		{
			tab: "EC Details",
			attributeName: "customer",
			label: "Customer",
			options: arrayToOptions(["TPE", "GTR", "Eversholt", "VMT", "Angel Trains", "RockRail", "Eurostar", "ScotRail", "SER", "SWR", "TfL", "EMR"]),
			locked: false,
			required: true,
			row: 1,
			size: 2,
			isMulti: true,
		},
		{
			tab: "EC Details",
			attributeName: "applicableFleets",
			label: "Fleet",
			options: arrayToOptions(["185", "333", "350/1", "350/2", "350/3", "350/4", "360", "374", "380", "444", "450", "700/0", "700/1", "707", "717", "DTUP"]),
			locked: false,
			required: true,
			row: 1,
			size: 2,
			isMulti: true,
		},
		{
			tab: "EC Details",
			attributeName: "ecTitle",
			label: "EC Title",
			locked: false,
			required: true,
			as: "textarea",
			size: "12",
			row: 2,
		},
		{
			tab: "EC Details",
			attributeName: "partADescription",
			label: "EC Description",
			locked: false,
			required: true,
			as: "textarea",
			size: "12",
			row: 2,
		},
		{
			tab: "EC Details",
			attributeName: "openCount",
			label: "Open Count",
			locked: false,
			required: true,
			row: 2,
		},
		{
			tab: "EC Details",
			attributeName: "closedCount",
			label: "Closed Count",
			locked: false,
			required: true,
			row: 2,
		},
		{
			tab: "EC Details",
			attributeName: "awaitingApprovalCount",
			label: "Awaiting Approval",
			locked: false,
			required: true,
			row: 2,
		},
	];


	return (
		<>
			{error ? (
				<FormBuilder 
					initialValues={{}}
					formEntry={formData}
					onCancel={() => console.info("cancelling")}
					onSubmit={(d) =>{
						console.info("onsubmit empty data: ", d);
						if(d){
							console.info("data returned", d);
							updateComment(d);
							alert("Updated Comments Data for EC " + d.ecNumber);
						}
					}}
					handleNewComment={() => console.info("new comment")}
					rowLabels={rowLabels}
				/>
			) : isLoading ? (
				<>Loading...</>
			) : commentData ? (
				<>
					{console.info("Comments Data found is: ", commentData)}
					<FormBuilder 
						initialValues={commentData}
						formEntry={formData}
						onCancel={() => console.info("cancelling")}
						onSubmit={(d) =>{
							if(data){
								console.info("data returned", d);
								updateComment(d);
								console.info("Sent Result? ", result);
								alert("Updated Comments Data for EC " + d.ecNumber);
							}
						}}
						handleNewComment={() => console.info("new comment")}
						rowLabels={rowLabels}
					/>
				</> ): ! data ? (
				<>
					<FormBuilder 
						initialValues={{}}
						formEntry={formData}
						onCancel={() => console.info("cancelling")}
						onSubmit={(d) =>{
							console.info("onsubmit empty data: ", d);
							if(d){
								console.info("data returned", d);
								updateComment(d);
								alert("Updated Comments Data for EC " + d.ecNumber);
							}
						}}
						handleNewComment={() => console.info("new comment")}
						rowLabels={rowLabels}
					/>
				</>) : <>reached unexpected state</>}
		</>
	);
};