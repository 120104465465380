import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

import { AuthState, AuthenticationToken } from "./auth.interfaces";

const initialState: AuthState = {};

const storedState = sessionStorage.getItem("token");
if (storedState) {
	initialState.token = JSON.parse(storedState);
	if (initialState.token) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const tokenInfo = jwtDecode<any>(initialState.token.access_token);
		if (tokenInfo) {
			initialState.user = {
				firstName: tokenInfo.preferred_given_name,
				lastName: tokenInfo.preferred_family_name,
				userName: tokenInfo.preferred_username, //sub undefined
				email: tokenInfo.email,
				orgCode: tokenInfo.org_code,
				manager: tokenInfo.incountry_manager,
			};

			initialState.accessTokenExpiry = (tokenInfo.exp - 30) * 1000;
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const refreshTokenInfo = jwtDecode<any>(initialState.token.refresh_token);
		if (refreshTokenInfo) initialState.refreshTokenExpiry = (refreshTokenInfo.exp - 30) * 1000;
		else initialState.refreshTokenExpiry = undefined;
	}
}

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		login: (state: Draft<AuthState>, action: PayloadAction<AuthenticationToken>) => {
			state.token = action.payload;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const tokenInfo = jwtDecode<any>(action.payload.access_token); //jwt decode the token
			if (tokenInfo) {
				state.user = {
					// extract information.
					firstName: tokenInfo.preferred_given_name,
					lastName: tokenInfo.preferred_family_name,
					userName: tokenInfo.preferred_username, //sub undefined
					email: tokenInfo.email,
					orgCode: tokenInfo.org_code,
					manager: tokenInfo.incountry_manager,
				};
				state.accessTokenExpiry = (tokenInfo.exp - 30) * 1000;
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			// const refreshTokenInfo = jwtDecode<any>(action.payload.refresh_token);
			// console.info(`refresh token is ${refreshTokenInfo}`);
			// if (refreshTokenInfo) state.refreshTokenExpiry = (refreshTokenInfo.exp - 30) * 1000;
			// else state.refreshTokenExpiry = undefined;
		},
		logout: (state: Draft<AuthState>) => {
			state.logoutToken = state.token?.id_token;
			state.token = undefined;
			state.user = undefined;
			state.accessTokenExpiry = undefined;
			state.refreshTokenExpiry = undefined;
		},
		loginNew: (state: Draft<AuthState>, action: PayloadAction<string>) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const tokenInfo = jwtDecode<any>(action.payload); //jwt decode the token
			if (tokenInfo) {
				state.user = {
					// extract information.
					firstName: tokenInfo.preferred_given_name,
					lastName: tokenInfo.preferred_family_name,
					userName: tokenInfo.gid, //sub undefined
					email: tokenInfo.mail,
					orgCode: tokenInfo.org_code,
					manager: tokenInfo.incountry_manager,
				};
				state.accessTokenExpiry = (tokenInfo.exp - 30) * 1000;
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			// const refreshTokenInfo = jwtDecode<any>(action.payload.refresh_token);
			// console.info(`refresh token is ${refreshTokenInfo}`);
			// if (refreshTokenInfo) state.refreshTokenExpiry = (refreshTokenInfo.exp - 30) * 1000;
			// else state.refreshTokenExpiry = undefined;
		},
		saveAccessToken: (state: Draft<AuthState>, action: PayloadAction<string>) => {
			if (action.payload !== undefined) {
				// console.info('saving access token:', action);
				state.idToken = action.payload;
			}
		},
	},
});

export const { logout, login, loginNew, saveAccessToken } = authSlice.actions;

export default authSlice.reducer;
