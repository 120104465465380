/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetCommentsQuery, useUpdateCommentMutation } from "../../../services/commentsData";
import { CommentsData, CommentsValue } from "../../table/dataTypes";
import { ColumnDef } from "@tanstack/react-table";
import { TableMaker } from "../../table/TableMaker";
import ModalWindow from "../../modal/ModalWindow";
import { CommentingForm } from "../../forms/CommentingForm";
import { compileFunction } from "vm";
import { Button } from "react-bootstrap";

// a table showing all raised comments - DONE
// clicking a row opens that specific comment details
// a button to add a comment to the 
export const CommentingPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [ecNumber, setECNumber] = useState<string>();


	useEffect(() => {
		const queryParameters = new URLSearchParams(location.search);
		const value = queryParameters.get("value");
		if (value !== undefined && value !== null) setECNumber(value);
		else {
			console.info("navigating to home...");
			navigate("/");
		}
	}, [location.search, navigate]);

	const pollingInterval = 0;
	const {
		data,
		error,
		isLoading,
	} = useGetCommentsQuery(ecNumber ? ecNumber : "", {
		pollingInterval,
	});

	console.info("Comments Data is: ", data);

	const columns = React.useMemo<ColumnDef<CommentsValue>[]>(
		() => [
			{
				header: `${ecNumber} - ${data?.ecTitle}`,
				columns: [
					{
						accessorKey: "id",
						header: "ID",
						footer: props => props.column.id,
					},
					{
						accessorKey: "status",
						header: "Status",
						footer: props => props.column.id,
					},
					{
						accessorKey: "category",
						header: "Category",
						footer: props => props.column.id,
					},
					{
						accessorKey: "raisedBy",
						header: "Raised By",
						footer: props => props.column.id,
					},
					{
						accessorKey: "dateRaised",
						header: "Date Raised",
						footer: props => props.column.id,
					},
          
					{
						accessorKey: "respondedBy",
						header: "Responded By",
						footer: props => props.column.id,
					},
					{
						accessorKey: "dateResponded",
						header: "Date Responded",
						footer: props => props.column.id,
					},
				],
			},
		],
		[]
	);


	const [show, setShow] = useState(false);
	const [commentId, setCommentId] = useState(0);
	const handleClose = () => setShow(false);
	const [updateComment, result] = useUpdateCommentMutation();


	return (
		<>
			{ isLoading ? (
				<>Loading...</>
			) : data ? (
				<>
             
					<div className="commentsTableContainer">
						<ModalWindow title={`EC ${ecNumber} ${data.ecTitle} - Comment ID: ${commentId}`} size="xl" show={show} onClose={handleClose}>
							<CommentingForm data={data.comments ? data.comments[commentId] ? data.comments[commentId] : { id: commentId} : { id: commentId} } handleSubmit={(formData) => {
								console.info("submitted form values are: ", formData);
								if(ecNumber !== undefined) {
									const newComments: CommentsValue[] = [];
									if(data.comments !== undefined)
									{
										data.comments.map((val) => {
											if(val.id === formData.id)
											{
												newComments.push(formData);
											}
											else{
												newComments.push(val);
											}
										});
										if(newComments.length === commentId)
										{
											newComments.push(formData);
										}
									}
									else
									{
										newComments.push(formData); 
									}
									const updatedData: CommentsData = { ecNumber: ecNumber, comments: newComments };
									console.info("Built data is: ", updatedData);
									updateComment(updatedData);
									alert("Updated Comments Data for EC " + ecNumber);
								}
							}} />
						</ModalWindow>
						<TableMaker data={data.comments ? data.comments : [{}]} columns={columns} handleRowClick={(rowData) => {
							console.info("Received data in parent component ", rowData);
							setCommentId(parseInt(rowData.id, 10));
							setShow(true);
						} } />
					</div>
					<div className="buttonContainer">
						<Button variant="primary" onClick={() => {
							setCommentId(data.comments ? data.comments.length : 0);
							setShow(true);
						}}>Add New Comment</Button>
					</div>
				</>
			): null}
			
		</>);
};