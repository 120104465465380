/* eslint-disable no-mixed-spaces-and-tabs */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CommentsData } from "../features/table/dataTypes";
import { RootState } from "../app/store";


export const commentsApi = createApi({
	reducerPath: "amaApi",
	baseQuery: fetchBaseQuery({ 
		baseUrl: "https://kraan4bu0f.execute-api.eu-west-1.amazonaws.com/", //feature-24
		// baseUrl: 'https://9uhyon2mgj.execute-api.eu-west-1.amazonaws.com/'
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.idToken ?? "accessTokenNotFound";
			// console.info('api token is: ', token);
			if (token) headers.set("Authorization", token);
			return headers;
		},
	 }),
	tagTypes: ["Comments"],
	endpoints: (builder) => ({
		GetComments: builder.query<CommentsData, string>({
			query: (name: string) => ({
				method: "GET",
				url: `getComments?ecNumber=${encodeURIComponent(name)}`,
			}),
			providesTags: (result) => (result ? ["Comments"] : ["Comments"]),
		}),
		ScanComments: builder.query<CommentsData[], string>({
			query: (name: string) => ({
				method: "GET",
				url: `scanComments?customer=${name}`,
			}),
			providesTags: (result) => (result ? ["Comments"] : ["Comments"]),
		}),
		ScanAllComments: builder.query<CommentsData[], string>({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			query: (empty: string) => ({
				method: "GET",
				url: "scanComments",
			}),
			providesTags: (result) => (result ? ["Comments"] : ["Comments"]),
		}),
		UpdateComment: builder.mutation<string, CommentsData>({
			query: (body: CommentsData) => ({
				method: "POST",
				url: "updateComments",
				body: body,
			}),
			invalidatesTags: ["Comments"],
		}),
	}),
});
  
// Export hooks for usage in functional components
export const {
	useGetCommentsQuery,
	useScanCommentsQuery,
	useScanAllCommentsQuery,
	useUpdateCommentMutation,
} = commentsApi;

  