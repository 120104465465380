const unixToDateString = (unixTimestamp: number) => {
	const date: Date = new Date(unixTimestamp * 1000);
	const months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	const year: number = date.getFullYear();
	const month: string = months[date.getMonth()];
	const day: number = date.getDate();
	const dateString = `${day} ${month} ${year}`;
	return dateString;
};

const getCurrentDate = () => {
	const date = new Date();
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const currentDate = `${day}-${month}-${year}`;
	return currentDate;
};

const getCurrentYear = () => {
	const date = new Date();
	const year = date.getFullYear();
	return year;
};

export { unixToDateString, getCurrentDate, getCurrentYear };
