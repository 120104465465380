/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable linebreak-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { FormBuilder } from "../formBuilder/FormBuilder";
import { AMAFormData, AMARowLabels } from "../formBuilder/formBuilder.interface";
import { useGetCommentsQuery, useUpdateCommentMutation } from "../../services/commentsData";
import { CommentsData, CommentsValue } from "../table/dataTypes";

export interface FormProps{
	handleSubmit: (values: any) => void | Promise<any>;
	data: CommentsValue;
}

export const CommentingForm = ({ data, handleSubmit } : (FormProps)) => {
	interface options {
		value: string | number;
		label: string | number;
	}

	const arrayToOptions = (object: string[] | number[]) => {
		const newArr: [options] = [{ value: "Not Allocated", label: "Not Allocated" }];
		for (let i = 0; i < object.length; i++) newArr.push({ value: object[i], label: object[i] });
		return newArr;
	};

	const formData: AMAFormData[] = [
		{
			attributeName: "id",
			label: "ID",
			required: false,
			row: 1,
			tab: "Comment",
			locked: true,
			size: 1,
		},
		{
			attributeName: "type",
			label: "Type",
			required: false,
			row: 1,
			tab: "Comment",
			options: arrayToOptions(["Customer Comment", "Siemens Comment", "Observation"]),
		},
		{
			attributeName: "category",
			label: "Category",
			required: false,
			row: 1,
			tab: "Comment",
			options: arrayToOptions(["Missing critical info - Technical", "Missing critical info – Non-Technical", "Technical error", "Admin error", "Clarification", "Suggestion", "Observation", "Other/Misc."]),

		},
		{
			attributeName: "severity",
			label: "Severity",
			required: false,
			row: 1,
			tab: "Comment",
			options: arrayToOptions([0,1,2,3,4,5]),
		},
		{
			attributeName: "status",
			label: "Status",
			required: false,
			row: 1,
			tab: "Comment",
			options: arrayToOptions(["Open", "Awaiting Approval", "Closed", "Conditionally Closed"]),
		},
		{
			attributeName: "clauseReference",
			label: "Clause Reference",
			required: true,
			row: 1,
			tab: "Comment",
		},
		{
			attributeName: "raisedBy",
			label: "Raised By",
			required: false,
			row: 2,
			tab: "Comment",
		},
		{
			attributeName: "dateRaised",
			label: "Date Raised",
			required: false,
			row: 2,
			tab: "Comment",
			type: "Date",
		},
		{
			attributeName: "comment",
			label: "Comment",
			required: false,
			row: 2,
			tab: "Comment",
			as: "textarea",
			size: 12,
		},
		{
			attributeName: "respondedBy",
			label: "Responded By",
			required: false,
			row: 3,
			tab: "Comment",
		},
		{
			attributeName: "dateResponded",
			label: "Date Responded",
			required: false,
			row: 3,
			tab: "Comment",
			type: "Date",
		},
		{
			attributeName: "response",
			label: "Response",
			required: false,
			row: 3,
			tab: "Comment",
			as: "textarea",
			size: 12,
		},
		{
			attributeName: "attachments",
			label: "Attachments",
			required: false,
			row: 3,
			tab: "Attachments",
			size: 12,
		},

	];

	const rowLabels: AMARowLabels[] = [
		{
			row: 1,
			tab: "Comment",
			title: "Comment Details",
		},
		{
			row: 2,
			tab: "Comment",
			title: "Raised Comment",
		},
		{
			row: 3,
			tab: "Comment",
			title: "Response to Comment",
		},
	];



	return (
		<>
			<FormBuilder 
				initialValues={data}
				formEntry={formData}
				onCancel={() => console.info("cancelling")}
				onSubmit={(d) =>{
					console.info("onsubmit empty data: ", d);
					if(d){
						console.info("data returned", d);
						handleSubmit(d);
					}
				}}
				handleNewComment={() => console.info("new comment")}
				rowLabels={rowLabels}
			/>
		</>
	);
};