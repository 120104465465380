/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import "./formBuilder.css";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import Select from "react-select";

import { FieldArray, FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";

import { AMAFormData, AMARowLabels } from "./formBuilder.interface";


const SelectBox = ({
	isMulti,
	initialValue,
	options,
	name,
	onChange,
}: {
	isMulti: boolean | undefined;
	initialValue: any;
	options: { value: string | number; label: string | number }[];
	name: any;
	onChange: any;
  }) => {
	const initialValueLabel = options.find((e) => e.value === initialValue);
	const [optionSelected, setSelectedOptions] = useState(() => {
		if(initialValue === undefined){
			return [{ value: initialValue, label: initialValueLabel?.label ?? initialValue }];
		} else
		{
			if(Array.isArray(initialValue))
			{
				// console.info("initial value is an Array", initialValue);
				const foundData: any = [];
				initialValue.forEach((element) => {
					foundData.push({value: element, label: element});
				});
				// console.log("found array data: ", foundData);
				return foundData;
			}
			else{
				return [{ value: initialValue, label: initialValue}];
			}
		}
	});
	// console.info("option selected is: ", optionSelected);
  
	const handleChange = (selected: any) => {
	  if (Array.isArray(selected)) onChange(extractOptionArrayValues(selected));
	  else onChange(selected.value);
	  setSelectedOptions(selected);
	};
  
	if (Array.isArray(optionSelected)) {
		if(optionSelected[0] !== undefined){
			if (isMulti && optionSelected[0].value === undefined) {
				return (
		  			<Select
						isMulti={isMulti ? true : false}
						options={options}
						isLoading={!options}
						closeMenuOnSelect={true}
						onChange={handleChange}
						value={optionSelected}
						name={name}
						className="Form-Select"
		  			/>
				);
	  		}
		}
	}
  
	return (
	  <Select
			isMulti={isMulti}
			options={options}
			isLoading={!options}
			closeMenuOnSelect={true}
			onChange={handleChange}
			value={optionSelected}
			name={name}
			className="Form-Select"
	  />
	);
};

export interface FormBuilderProps {
  onCancel: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onReset?: (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;

  handleNewComment: (values: any) => void | Promise<any>;

  rowLabels?: AMARowLabels[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formEntry: AMAFormData[];
}

export const FormBuilder = ({ formEntry, initialValues, onSubmit, onCancel, onReset, rowLabels, handleNewComment }: FormBuilderProps) => {
	// console.info("Entered Form Builder, Initial values: ", initialValues);
	// const parsedData: Tabs[] = parseData(formEntry, initialValues);
	// const schema = Yup.object().shape(buildYupSchema(parsedData));
	const [parsedData, setParsedData] = useState(parseData(formEntry, initialValues));
	const [schema, setSchema] = useState(Yup.object().shape(buildYupSchema(parsedData)));

	useEffect(()=>{
		setParsedData(parseData(formEntry, initialValues));
	},[initialValues, rowLabels]);

	const formik = useFormik({
		initialValues,
		onSubmit,
		onReset,
		validationSchema: schema,
	});

	const [tabClick, setTabClick] = useState((false));

	useEffect(()=>{
		console.info("Entered rerender of text area");
		const tx = document.getElementsByTagName("textarea");
		for (let i = 0; i < tx.length; i++) {
			if(tx[i].scrollHeight > 400)
			{
				tx[i].setAttribute("style", "height:" + (400) + "px;");
			}
			else
			{
				tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
			}
		}
	},[tabClick]);

	return (
		<Form onSubmit={formik.handleSubmit}>
			<Tabs id="Form" className="form" onClick={() => {setTabClick(!tabClick);}}>
				{parsedData.map((tab, index) => (
					<Tab key={index} eventKey={tab.name} title={tab.name}>
						{tab.data.map((row, index2) => (
							<>
								{rowHasLabel(index2, rowLabels, tab.name) && (
									<label className="AMAFormRowLabel">{returnRowLabelTitle(index2, rowLabels, tab.name)}</label>
								)}
								<Row key={index2} className="AMAFormRow" title={returnRowLabelTitle(index2, rowLabels, tab.name)}>
									{row.Row?.map((rowData, index3) => {
										return (
											<Col xs={rowData.size ?? "auto"} key={index3}>
												{rowData.checkbox && (
													<>
														<Form.Label>{""}</Form.Label>
														<Form.Check
															type="checkbox"
															defaultValue={rowData.value}
															readOnly={rowData.editable}
															disabled={rowData.editable}
															onChange={formik.handleChange}
															label={rowData.label}
															name={rowData.name}
															id={rowData.name}
															className={
																rowData.required &&
                        		 								getAttributeData(formik.errors, rowData.name, false, rowData.checkbox, rowData.type) &&
                        										getAttributeData(formik.touched, rowData.name, false, rowData.checkbox, rowData.type)
																	? "input-error"
																	: ""
															}
														/>
													</>
												)}
												{!rowData.options && !rowData.checkbox && (
													<>
														<Form.Label>{rowData.label}</Form.Label>
														<Form.Control
															type={rowData.type}
															readOnly={rowData.editable}
															disabled={rowData.editable}
															as={rowData.as}
															placeholder={rowData.placeholder}
															defaultValue={rowData.value}
															onChange={formik.handleChange}
															name={rowData.name}
															className={
																rowData.required &&
                                								getAttributeData(formik.errors, rowData.name, false, rowData.checkbox, rowData.type) &&
                                								getAttributeData(formik.touched, rowData.name, false, rowData.checkbox, rowData.type)
																	? "input-error"
																	: ""
															}
															// onKeyUp={() => setTabClick(!tabClick)}
															// eslint-disable-next-line react/jsx-no-duplicate-props
														/>
													</>
												)}
												{rowData.options && !rowData.checkbox && (
													<>
														<Form.Label>{rowData.label + (rowData.required ? "*" : "")}</Form.Label>
														<SelectBox
															isMulti={rowData.isMulti}
															initialValue={rowData.value}
															options={rowData.options}
															name={rowData.name}
															onChange={(e: any) => {
																// console.info("Setting field:" + rowData.name + " to value:", e);
																formik.setFieldValue(rowData.name, e);
															}}
														/>
													</>
												)}
												{rowData.required &&
													getAttributeData(formik.errors, rowData.name, false, rowData.checkbox, rowData.type) &&
													getAttributeData(formik.touched, rowData.name, false, rowData.checkbox, rowData.type) && (
													<>
														<p className="error">
															{getAttributeData(formik.errors, rowData.name, false, rowData.checkbox, rowData.type)}
														</p>
													</>
												)}
											</Col>
										);
									})}
								</Row>
							</>
						))}
						{tab.name === "EC Comments" && (
							<div className="AMAFormButton">
								<Button type="button" onClick={(data) => {
									// console.info("clicking new comment");
									handleNewComment("");
								}}>Add Comment</Button>
							</div>
						)}
						
					</Tab>
				))}
			</Tabs>
			<div className="AMAFormButton">
				<Button type="submit">Submit form</Button>
			</div>
		</Form>
	);
};

function rowHasLabel(row: number, rowLabels: AMARowLabels[] | undefined, tab: string) {
	let hasLabel = false;
	if (rowLabels !== undefined) {
		rowLabels.map((x) => {
			if (x.row == row + 1 && tab == x.tab) {
				// console.info("found title is " + x.title);
				hasLabel = true;
			}
		});
	}
	return hasLabel;
}

function returnRowLabelTitle(row: number, rowLabels: AMARowLabels[] | undefined, tab: string) {
	let title = "";
	if (rowLabels !== undefined) {
		rowLabels.map((x) => {
			if (x.row == row + 1 && tab == x.tab) {
				// console.info("found title is " + x.title);
				title = x.title;
			}
		});
	}
	return title;
}

function getLargestRowValue(formEntry: AMAFormData[]) {
	let maxValue = 1;
	for (let i = 0; i < formEntry.length; i++) if (formEntry[i].row > maxValue) maxValue = formEntry[i].row;

	return maxValue;
}

function getTabs(formData: AMAFormData[]) {
	const tabNames: string[] = [];
	for (let i = 0; i < formData.length; i++) if (!tabNames.includes(formData[i].tab ?? "")) tabNames.push(formData[i].tab ?? "");

	return tabNames;
}

function getAttributeData(
	data: any,
	property: string,
	timestamp: boolean | undefined,
	checkbox: boolean | undefined,
	type: string | undefined,
) {
	// console.info("data", data);
	// console.info("attributeName", property);

	const attributes = property.split(".");
	// console.info("attributes", attributes);
	// console.info(data.comments[0]);
	// console.info(data["comments"]);
	// console.info("Attributes length: " + attributes.length + ", Length of [0].split([) is: " + attributes[0].includes("["));
	let returnValue;
	if(attributes[0].includes("["))
	{
		const arrayAttribute = attributes[0].split("[");
		if(arrayAttribute === undefined)
		{
			return undefined;
		}
		// console.info("Array Attribute 0: " + arrayAttribute[0]);
		// console.info("Data is: ", data[arrayAttribute[0]]);
		const arrayData = data[arrayAttribute[0]];
		if(arrayData === undefined)
		{
			return undefined;
		}
		// console.info("Array Attribute 1: " + arrayAttribute[1].match(/\d/g));
		const index = Number(arrayAttribute[1].match(/\d/g));
		if(index === undefined)
		{
			return undefined;
		}
		// console.info("data at index " + index, arrayData[index]);
		const indexData = arrayData[index];
		if(indexData === undefined)
		{
			return undefined;
		}
		returnValue = indexData[attributes[1]];
		return indexData[attributes[1]];
	}
	for (let i = 0; i < attributes.length; i++) {
		// console.info("i: " + i + " data:", data[attributes[i]]);
		if (returnValue === undefined) returnValue = data[attributes[i]];
		else returnValue = returnValue[attributes[i]];
	}
	if (type == "date") return unixToString2(returnValue);
	if (timestamp) return unixToString(returnValue);
	if (checkbox && returnValue === undefined) return false;
	// console.info("found value is: " + returnValue);
	return returnValue;
}

function unixToString(unix: number) {
	if (unix === undefined) return "";

	const dateObject = new Date(unix * 1000);
	const stringDateTime = dateObject.toLocaleString();
	const splitStringArr = stringDateTime.split(", ");
	const stringDate = splitStringArr[0];
	return stringDate;
}

function unixToString2(unix: number | string) {
	//this function accounts for unix values and string vals
	if (typeof unix === "number") {
		const dateObject = new Date(unix * 1000);
		const stringDateTime = dateObject.toLocaleString();
		const splitStringArr = stringDateTime.split(", ");
		const stringDate = splitStringArr[0];
		const _arr = stringDate.split("/");
		const formattedStringDate = `${_arr[2]}-${_arr[1]}-${_arr[0]}`;
		return formattedStringDate;
	} else return unix;
}

function parseData(formData: AMAFormData[], data: any) {
	const Tabs: string[] = getTabs(formData);
	const returnData: Tabs[] = [];
	for (let i = 0; i < Tabs.length; i++) {
		const TabData = formData.filter((input) => input.tab === Tabs[i]);
		const fieldRow: FieldRow[] = [];
		for (let j = 1; j <= getLargestRowValue(TabData); j++) {
			const row = TabData.filter((input) => input.row === j);
			const fieldData: FieldData[] = [];
			for (let k = 0; k < row.length; k++) {
				fieldData.push({
					label: row[k].label,
					name: row[k].attributeName,
					value: getAttributeData(data, row[k].attributeName, row[k].timestamp, row[k].checkbox, row[k].type),
					as: row[k].as,
					placeholder: row[k].placeholder,
					options: row[k].options,
					size: row[k].size,
					required: row[k].required,
					editable: row[k].locked,
					checkbox: row[k].checkbox,
					type: row[k].type,
					isMulti: row[k].isMulti,
				});
			}
			fieldRow.push({
				Row: fieldData,
			});
		}
		returnData.push({
			data: fieldRow,
			name: Tabs[i],
		});
	}
	return returnData;
}

function extractOptionArrayValues(data: { value: string | number; label: string | number }[]) {
	// console.info("Extracting array values of: ", data);
	const extractedData: any = [];
	data.forEach((d) => {
	  extractedData.push(d.value);
	});
	// console.info("extraced data is: ", extractedData);
	return extractedData;
}

function buildYupSchema(parsedData: Tabs[]) {
	const object: any = {};
	for (let i = 0; i < parsedData.length; i++) {
		for (let j = 0; j < parsedData[i].data.length; j++) {
			const rowLength: FieldData[] = parsedData[i].data[j].Row ?? [];
			for (let k = 0; k < rowLength.length; k++) {
				if (rowLength[k].required) {
					// to implement later...
				}
			}
		}
	}
	// console.info("yupSchema is", object);
	return object;
}

interface Tabs {
  data: FieldRow[];
  name: string;
}

interface FieldRow {
  Row?: FieldData[];
}

interface FieldData {
  label: string; //done
  name: string;
  value?: string | number | undefined; //done
  as?: any | undefined; //done
  placeholder?: string | undefined; //done
  options?: {
    value: string | number;
    label: string | number;
  }[];
  isMulti?: boolean | undefined;
  size?: ColSpec; //done
  required?: boolean;
  editable?: boolean | undefined;
  checkbox?: boolean | undefined;
  type?: string | undefined;
}
type NumberAttr = number | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12";
type ColSize = boolean | "auto" | NumberAttr;
type ColSpec =
  | ColSize
  | {
      span?: ColSize;
      offset?: NumberAttr;
      order?: ColOrder;
    };
type ColOrder = ColOrderNumber | "first" | "last";
type ColOrderNumber = number | "1" | "2" | "3" | "4" | "5";
