/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import { Button, Modal } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

export interface ModalProps {
  title?: string;
  closeButton?: boolean;
  saveButton?: boolean;
  size?: "sm" | "lg" | "xl" | undefined;
  children: JSX.Element | JSX.Element[];
  fullscreen?: string | true | undefined;
  show: boolean;
  link?: boolean;
  tiNumber?: number;
  onClose: () => void;
  withConfirm?: boolean;
}

const ModalWindow = ({
	title,
	closeButton,
	saveButton,
	size,
	children,
	fullscreen,
	show,
	onClose,
	withConfirm = true,
}: ModalProps) => {
	// const navigate = useNavigate();

	let showHeader = false;
	if (title !== undefined) showHeader = true;

	let showFooter = false;
	if (closeButton || saveButton) showFooter = true;

	// let showLink = false;
	// if (link) showLink = true;

	const handleOnClose = () => {
		const is_closing =
      !withConfirm ||
      confirm(
      	// eslint-disable-next-line no-mixed-spaces-and-tabs
      	"Have you saved all your changes?\n Click Ok if you have, or don't want to save any changes.\n Click Cancel and then Submit Form if you have not.",
      );

		if (is_closing) onClose();
	};

	// const handleNavigate = () => {
	// 	navigate(`/technicalIssue?value=${tiNumber}`);
	// };

	return (
		<>
			<Modal show={show} centered keyboard={true} size={size} fullscreen={fullscreen}>
				{showHeader && (
					<Modal.Header closeButton onHide={handleOnClose}>
						<Modal.Title>{title}</Modal.Title>
						{/* {showLink && (
							// <div className="linkButton">
							// 	<ExternalLink onClick={handleNavigate} />
							// </div>
						)} */}
					</Modal.Header>
				)}
				<Modal.Body>{children}</Modal.Body>
				{showFooter && (
					<Modal.Footer>
						{closeButton && (
							<Button variant="secondary" onClick={onClose}>
                Close
							</Button>
						)}
						{saveButton && (
							<Button type="submit" variant="primary">
                Submit
							</Button>
						)}
					</Modal.Footer>
				)}
			</Modal>
		</>
	);
};

export default ModalWindow;
