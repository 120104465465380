/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
	Column,
	Table as ReactTable,
	PaginationState,
	useReactTable,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	ColumnDef,
	OnChangeFn,
	flexRender,
} from "@tanstack/react-table";
import { CommentsData } from "./dataTypes";
import { Table } from "./Table";



export interface TableProps{
	handleRowClick: (values: any) => void | Promise<any>;
	data: any[]
	columns: ColumnDef<any>[];
}

export const TableMaker = ({ data, columns, handleRowClick } : (TableProps)) => {
	const pollingInterval = 0;
	
	console.info("Data for table is: ", data);
	
	return(
		<>
			<Table {...{
				data,
				columns,
				handleRowClick
			}}
			/>
		</>
	);


};