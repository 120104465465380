import React from "react";
import { RouteObject } from "react-router-dom";
import { CommentsPage } from "./features/comments/components/CommentsPage";
import { NavBar } from "./features/navBar/components/navBar";
import PageFooter from "./features/footer/components/PageFooter";
import { CheckAuthentication } from "./features/newAuth/components/CheckAuthentication";
import { CommentingPage } from "./features/comments/components/CommentingPage";

const routes: RouteObject[] = [
	{
		path: "/",
		element: (
			<>
				<CheckAuthentication>
					<NavBar />
					<CommentsPage />
					<PageFooter />
				</CheckAuthentication>
			</>
		),
	},
	{
		path: "/ecComment",
		element: (
			<>
				<CheckAuthentication>
					<NavBar />
					<CommentingPage />
					<PageFooter />
				</CheckAuthentication>
			</>
		),
	},
];


export { routes };